.document-dashboard-layout-page {
    min-height: 100vh;
}

.doc-status {
    position: absolute;
    bottom: 10px;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
    }
    .completed-status {
        color: green;
    }
    .pending-status {
        color: purple;
    }
}


.document-progress {
    width: 40%;
    position: absolute;
    bottom: 16px;
    right: 19px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    text-transform: capitalize;
}

.search-input-bar-container {
    display: flex;
    justify-content: center;
    .search-input-bar {
        border: 1px solid #8080804d;
        box-shadow: 2px 2px #8080802b;
        border-radius: 20px;
        margin: 1% 0;
        width: 95%;
        padding: 1rem;
        font-size: 1rem;
        line-height: 1rem;
    }
}
