
.resizable-drag-component{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    
    .component-field-icon {
        display: none;
        position: absolute;
        top: -5px;
        right: -9px;
        .delete-field-icon{
            width: 16px;
            height: 16px;
        }
    }
    &:hover {
        > .component-field-icon {
          display: block;
        }
      }
}


.text-component-display-value{
  margin: 8px;
  font-size: 20px;
  width: 100%;
}

.hand-written-text {
  // font-family: 'Gochi Hand', cursive;
  font-family: 'Handlee', cursive;
  color: blue;
}

.printed-text{
  color: black;
}
