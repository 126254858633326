
.select-flow-type-layout-page {

    height: 100%;
    min-height: 100vh;
    padding: 2%;

    .flow-question{
        font-size: 32px;
        text-align: center;
        font-weight: 600;
        font-family: monospace;
    }
    .flow-cards-container{
        display: flex;
        justify-content: center;
        .flow-card-div{
            width: 350px;
            margin: 20px;
        }
    }
}

.select-flow-title-banner {
    margin-top: 48px; 
    font-size: 2.25rem; 
    line-height: 1.2; 
    text-align: center;
    font-weight: bold;
    color: #737373;
    height: 3rem;
}

.add-signers-layout-page {
    .add-signer-fields-container{
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 1% 18%;
        padding: 5%;
        box-shadow: #80808059 1px 1px 1px 1px;
        .signer-name{
            margin: 2%;
        }
    }
}

.review-and-send-email-layout-page {
    .email-body-container{
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 1% 18%;
        padding: 5%;
        box-shadow: #80808059 1px 1px 1px 1px;
        
        .subject-field{
            width:100%;
            margin: 0% 0;
        }
        .email-text-area{
            width: 100%;
            height: 100px;
            font-size: 16px;
            padding: 2%;
        }

        .send-document-button {
            width: 200px;
            // margin: 20px;
            background: blueviolet;
            display: flex;
            float: right;
        }
    }
}
