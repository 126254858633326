
.signature-modal{

    .modal-button-container{
        display: flex;
        float: right;
    }
    .modal-button {
        margin: 8px;
        padding: 8px;
        background: blueviolet; 
        // deeppink;
        color: white;
        font-size: 16px;
        font-weight: bolder;
    }
}
