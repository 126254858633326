.docmaker-title{
    top: 0px;
    background: blueviolet; 
    /* deeppink; */
    color: white;
    position: sticky;
    z-index: 99999;
    padding: 10px;
    margin: 0px;
}

.doc-maker-layout-page {
    display: flex;
    height: 100%;
    width: 100%;
}

.wrapper-doc-layer-container{
    position: relative;
    height: 100%;
    width: 70%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    margin: 30px 0px;
}

.wrapper-doc-sub-container {
    width: 1150px;
    position: relative;
}

.pdf-document-background-layer{
    box-shadow: 2px 3px 3px 3px #00000073;
    width: inherit;
}


.draggable-overlay-layer{
    position: absolute;
    top: 0px;
    // width: 100%;
    width: inherit;
    height: 100%;
}

.field-side-bar-layout{
    position: fixed;
    right: 0px;
    width: 30%;
    height: 100vh;
    background: rgb(231, 235, 240);
    color: black;
}

.field-side-bar-title{
    text-align: center;
}

.fields-container {
    padding: 10px;
}

.signer-fields-container{
    display: flex;
    flex-wrap: wrap;
}

.sender-fields-container{
    display: flex;
    flex-wrap: wrap;
}

.field-component {
    width: 30%;
    background: white;
    color: black;
    margin: 1%;
    padding: 2%;
    text-align: center;
}


.view-button{
    font-size: 18px;
    font-weight: bolder;
    padding: 10px 30px;
    background: gold;
}



.resizable-drag-component{
    width: 100%;
    height: 100%;

    
    .component-field-icon {
        display: none;
        position: absolute;
        top: -5px;
        right: -9px;
        .delete-field-icon{
            width: 16px;
            height: 16px;
        }
    }
    &:hover {
        > .component-field-icon {
          display: block;
        }
      }
}
